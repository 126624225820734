import { seoApi } from '~/api/seo'
interface ResponseAPI {
  code: number,
  data: { gtm_id: string | string[], google_site_verification: string, domain: string, favicon: string, logo: string }
}
export default defineNuxtPlugin(async (nuxtApp) => {
  const headers = useRequestHeaders()
  const { $config } = nuxtApp
  const { BRAND_INFO } = seoApi()
  const { data } = await useAsyncData<ResponseAPI>('gtm-status', () =>
    $fetch(`${$config.API_BASE_URL}${BRAND_INFO}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        cookie: `brand_code=${$config.public.BRAND_CODE}; host=${headers.host}`
      }
    })
  )

  if (data.value?.code === 200) {
    const gtmId = (data.value?.data?.gtm_id as string[])?.join?.(',') ?? data.value?.data?.gtm_id
    const googleSiteVerification = data.value.data.google_site_verification
    useHead({
      script: [
        {
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');`
        }
      ],
      meta: [
        {
          name: 'google-site-verification',
          content: googleSiteVerification
        }
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: data.value.data.favicon }
      ],
      noscript: [
        {
          tagPosition: 'bodyOpen',
          innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        }
      ]
    })
  }
})
